.rd {
  background: radial-gradient(circle, #ddd 0%, #333 100%); /* Monochrome gradient from light to dark */
  border-radius: 50%;
}


.accordion {
  width: 70%;
  margin: 0 auto;
margin-top: 3rem;
  border-radius: 5px;
}

.accordion-item {
 margin-bottom: 20px;
 background-color: white;
 border-radius: 10px;
}

.accordion-header {
  background-color: #fff;
  padding: 30px 25px;
  cursor: pointer;
  border-radius: 10px;

}

.accordion-header h3 {
  margin: 0;
  font-size: 18px;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  
}

.accordion-content.open {
  max-height: 100px; /* Adjust as needed */
  padding: 20px;
}



header {
  border: '1px solid #0000001a'
}
@media only screen and (max-width: 640px) {
  header {
    border: 0;
  }
  .accordion {
    width: 90%;
  }
  .accordion-header{
    padding: 20px ;
  }
}